import React from "react";
import "./loader.scss";

type LoaderProps = {
  loader: React.ReactNode;
};

export const Loader: React.FC<LoaderProps> = ({
  loader
}) => (
  <div className="loader">
    <div className='loader__img'>
      {loader}
    </div>
  </div>
);