import React from "react"
import { Page } from "../../../../components/Page"
import { PaymentStatus } from "../../../../components/PaymentStatus"
import { BodyRegularM, Button } from "../../../../components/UI"
import { Divider } from "../../../../components/UI/Divider"
import { Column } from "../../../../components/UI/Grid/Column"
import { Row } from "../../../../components/UI/Grid/Row"
import { VerticalOffset } from "../../../../components/UI/Grid/VerticalOffset"
import { TableData, TableDataItem } from "../../../../components/UI/TableData"
import { useAppSelector } from "../../../../hooks"
import Services from "../../../../utils/services"
import { languageConstants } from "../../../language/languageSlice"
import { selectStatus } from "../../invoiceSlice"

type StepCryptoSuccessProps = {
  shopUrl: string
}
export const StepCryptoSuccess: React.FC<StepCryptoSuccessProps> = ({ shopUrl }) => {
  const langConstants = useAppSelector(languageConstants)
  const status = useAppSelector(selectStatus)
  const amount = status
    ? status.invoice.amount + status.invoice.amount * status.commissionOrBonus
    : 0
  const cryptoAmount = status?.invoice.inputCryptoFee
    ? status?.invoice.payAmount + status?.invoice.inputCryptoFee
    : status?.invoice.payAmount

  const openHashbonRegistration = () => {
    window.location.href = "https://hashbon.com/register"
  }
  return (
    <Page title={langConstants["L_PAYMENTS_BY_BITCOIN"]} width="full">
      <VerticalOffset offset="level3">
        <PaymentStatus status="success" message="" />

        <Divider type="dash" />

        <TableData>
          <TableDataItem
            name={langConstants["L_INVOICE_ID"]}
            value={{
              text: `${status?.invoice.id}`,
            }}
          />
          {status && status.invoice.itemName && (
            <TableDataItem
              name={`${langConstants["L_PRODUCT_NAME"]}`}
              value={{
                text: `${status?.invoice.itemName}`,
              }}
            />
          )}

          <TableDataItem
            name={langConstants["L_TOTAL_PRICE"]}
            value={{
              text: `${amount} ${status?.invoiceCurrency.shortName}`,
              color: "secondary",
            }}
          />
          {cryptoAmount && (
            <TableDataItem
              name={langConstants["L_AMOUNT_OF_PAYMENT"]}
              value={{
                text: `${Services.numberWithoutExp(cryptoAmount)} ${
                  status?.paymentCurrency.shortName
                }`,
                color: "secondary",
              }}
            />
          )}
        </TableData>

        <Row align="center">
          <Column col={12} sm={{ col: "auto" }}>
            <Button width="full-mobile" size="medium" onClick={openHashbonRegistration}>
              <BodyRegularM>{langConstants["L_REGISTER_HASHBON_WALLET"]}</BodyRegularM>
            </Button>
          </Column>

          {shopUrl && (
            <Column col={12} sm={{ col: "auto" }}>
              <a href={shopUrl}>
                <Button width="full-mobile" size="medium" variant="secondary">
                  <BodyRegularM>{langConstants["L_RETURN_TO_SHOP_FAIL"]}</BodyRegularM>
                </Button>
              </a>
            </Column>
          )}
        </Row>
      </VerticalOffset>
    </Page>
  )
}
