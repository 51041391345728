import React from "react"
import "./divider.scss"
import cn from "classnames"

type DividerProps = {
  type?: Type
}

type Type = "solid" | "dash"

export const Divider: React.FC<DividerProps> = ({ type = "solid" }) => (
  <div className={`divider ${cn(type ? `divider--type-${type}` : "")}`}></div>
)
