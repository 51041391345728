import React from "react";
import "./link.scss";
// import { NavLink } from "react-router-dom";

type LinkProps = {
  href: string;
  children: React.ReactNode;
  type?: Type;
  target?: "_blank";
};

// export const Link: React.FC<LinkProps> = ({
//   href,
//   children,
// }) => (
//   <NavLink to={href} className="link">
//     {children}
//   </NavLink>
// );

type Type = "mailto" | "tel" | "href";

export const Link: React.FC<LinkProps> = ({
  href,
  children,
  type = "href",
  target,
}) => (
  <>
    {type === "href" && (
      <a href={href} className="link" target={target}>
        {children}
      </a>
    )}

    {type === "tel" && (
      <a href={`${type}:${href}`} className="link">
        {children}
      </a>
    )}

    {type === "mailto" && (
      <a href={`${type}:${href}`} className="link">
        {children}
      </a>
    )}
  </>
);
