import React from "react"
import cn from "classnames"
import { Title } from "../Title"
import { Container } from "../UI/Grid/Container/Container"
import "./page.scss"

type PageProps = {
  title: string
  lead?: string
  width?: "full" | "auto"
  crypto?: React.ReactNode
  children: React.ReactNode
  cryptoLead?: string
}

export const Page: React.FC<PageProps> = ({ title, lead, width, crypto, children, cryptoLead }) => {
  return (
    <div className="page">
      <Container>
        <div className={`page__body ${cn(width ? `page__body--width-${width}` : "")}`}>
          <Title title={title} lead={lead} crypto={crypto} cryptoLead={cryptoLead} />

          <div className="page__content">{children}</div>
        </div>
      </Container>
    </div>
  )
}
