import axios from "axios";
import { APP_ENV } from "../../app/enviroment";
import { languageType } from "../invoice/types";

const createUrl = (lang: languageType) => {
  const urlMain = APP_ENV.REACT_APP_API_URL
  const url = lang === "en" ? `${urlMain}/api/front/get-language-constants` : `${urlMain}/${lang}/api/front/get-language-constants`
  return url
}

export const getLanguageConstants = (lang: languageType) => 
  axios.get(createUrl(lang))
    .then((result) => {
      if (result.data.type === 'error') {
        throw new Error(result.data.text)
      }
      return result.data
    })