import React from "react"
import { languageConstants } from "../../features/language/languageSlice"
import { useAppSelector } from "../../hooks"
import { IconError, IconSuccessfull } from "../UI/IconImg"
import { BodyRegularM, BodySemiboldL } from "../UI/Text"
import "./payment-status.scss"

type PaymentStatusProps = {
  status: "success" | "error" | "ok"
  message: string
}

export const PaymentStatus: React.FC<PaymentStatusProps> = ({ status, message }) => {
  const langConstants = useAppSelector(languageConstants)

  return (
  <div className="payment-status">
    {status === "success" && (
      <>
        <div className="payment-status__icon">
          <IconSuccessfull size="big" />
        </div>

        <div className="payment-status__body">
          <BodySemiboldL>{langConstants["L_RETURN_TO_SHOP_SUCCESS_MSG"]}</BodySemiboldL>
          <BodyRegularM color="four">
            {message}
          </BodyRegularM>
        </div>
      </>
    )}

    {status === "error" && (
      <>
        <div className="payment-status__icon">
          <IconError size="big" />
        </div>

        <div className="payment-status__body">
          <BodySemiboldL>{langConstants["L_PAYMENT_FAILED"]}</BodySemiboldL>
          <BodyRegularM color="four">
            {message}
          </BodyRegularM>
        </div>
      </>
    )}
  </div>
)}
