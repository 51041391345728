import React from "react";
import "./logo.scss";
import ImgLogo from "../../../assets/img/img-logo.svg";


type LogoProps = {
  img?: string;
};

export const Logo: React.FC<LogoProps> = ({ img = ImgLogo }) => {
  return (
    <div className="logo" >
      <img src={img} alt="Hashbon" />
    </div>
    // <a className="logo" href="/">
    //   <img src={img} alt="Matbea" />
    // </a>
  );
};
