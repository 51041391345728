import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

type languageRequestType = {
    langConstants: {[index: string]: string}
}
const initialState: languageRequestType = {
    langConstants: {}
}
export const languageSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        updateConstants: (state, action) => {
            if(state.langConstants["L_LANG"] !== action.payload["L_LANG"]){
                state.langConstants = action.payload
            }
        }
    }
})

export const {updateConstants} = languageSlice.actions

export const languageConstants = (state: RootState) => state.language.langConstants

export default languageSlice.reducer