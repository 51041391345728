import React from "react";
import { IconImg, Size } from "./IconImg";
import IconFlagRusBase from "../../../assets/icon-img/icon--flag-rus.svg";
import IconFlagEsBase from "../../../assets/icon-img/icon--flag-es.svg";
import IconFlagEngBase from "../../../assets/icon-img/icon--flag-eng.svg";
import IconFlagDeBase from "../../../assets/icon-img/icon--flag-de.svg";
import IconFlagCzBase from "../../../assets/icon-img/icon--flag-cz.svg";
import IconFlagFrBase from "../../../assets/icon-img/icon--flag-fr.svg";
import IconFlagArBase from "../../../assets/icon-img/icon--flag-ar.svg";
import IconFlagFaBase from "../../../assets/icon-img/icon--flag-fa.svg";
import IconFlagBrBase from "../../../assets/icon-img/icon--flag-br.svg";
import IconFlagTrBase from "../../../assets/icon-img/icon--flag-tr.svg";
import IconErrorSBase from "../../../assets/icon-img/icon--error-s.svg";
import IconErrorBase from "../../../assets/icon-img/icon--error.svg";
import IconSuccessfullBase from "../../../assets/icon-img/icon--successfull.svg";
import IconBitcoinSmallBase from "../../../assets/icon-img/icon--bitcoin-small.svg";
import IconBnbSmallBase from "../../../assets/icon-img/icon--bnb-small.svg";
import IconBusdSmallBase from "../../../assets/icon-img/icon--busd-small.svg";
import IconDashSmallBase from "../../../assets/icon-img/icon--dash-small.svg";
import IconDogeSmallBase from "../../../assets/icon-img/icon--doge-small.svg";
import IconEthSmallBase from "../../../assets/icon-img/icon--eth-small.svg";
import IconLitecoinSmallBase from "../../../assets/icon-img/icon--litecoin-small.svg";
import IconMoneroSmallBase from "../../../assets/icon-img/icon--monero-small.svg";
import IconTerraSmallBase from "../../../assets/icon-img/icon--terra-small.svg";
import IconTetherSmallBase from "../../../assets/icon-img/icon--tether-small.svg";
import IconUsdcSmallBase from "../../../assets/icon-img/icon--usdc-small.svg";
import IconZcashSmallBase from "../../../assets/icon-img/icon--zcash-small.svg";
import IconLoaderSmallBase from "../../../assets/icon-img/icon--loader-small.svg";


type IconImgUIProps = {
  size?: Size;
};

export const IconLoaderSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconLoaderSmallBase} name="icon--loader-small" size={size} />
);
export const IconZcashSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconZcashSmallBase} name="icon--zcash-small" size={size} />
);
export const IconUsdcSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconUsdcSmallBase} name="icon--usdc-small" size={size} />
);
export const IconTetherSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconTetherSmallBase} name="icon--tether-small" size={size} />
);
export const IconTerraSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconTerraSmallBase} name="icon--terra-small" size={size} />
);
export const IconMoneroSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconMoneroSmallBase} name="icon--monero-small" size={size} />
);
export const IconLitecoinSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconLitecoinSmallBase} name="icon--litecoin-small" size={size} />
);
export const IconEthSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconEthSmallBase} name="icon--eth-small" size={size} />
);
export const IconDogeSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconDogeSmallBase} name="icon--doge-small" size={size} />
);
export const IconDashSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconDashSmallBase} name="icon--dash-small" size={size} />
);
export const IconBusdSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconBusdSmallBase} name="icon--busd-small" size={size} />
);
export const IconBnbSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconBnbSmallBase} name="icon--bnb-small" size={size} />
);
export const IconBitcoinSmall: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconBitcoinSmallBase} name="icon--bitcoin-small" size={size} />
);
export const IconSuccessfull: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconSuccessfullBase} name="icon--successfull" size={size} />
);
export const IconError: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconErrorBase} name="icon--error" size={size} />
);
export const IconErrorS: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconErrorSBase} name="icon--error-s" size={size} />
);
export const IconFlagRus: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconFlagRusBase} name="icon--flag-rus" size={size} />
);
export const IconFlagEs: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconFlagEsBase} name="icon--flag-es" size={size} />
);
export const IconFlagEng: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconFlagEngBase} name="icon--flag-eng" size={size} />
);
export const IconFlagDe: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconFlagDeBase} name="icon--flag-de" size={size} />
);
export const IconFlagCz: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconFlagCzBase} name="icon--flag-cz" size={size} />
);
export const IconFlagFr: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconFlagFrBase} name="icon--flag-fr" size={size} />
);
export const IconFlagAr: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconFlagArBase} name="icon--flag-ar" size={size} />
);
export const IconFlagFa: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconFlagFaBase} name="icon--flag-fa" size={size} />
);
export const IconFlagBr: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconFlagBrBase} name="icon--flag-br" size={size} />
);
export const IconFlagTr: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconFlagTrBase} name="icon--flag-tr" size={size} />
);
