import { useEffect, useState } from 'react'
import { IValidations } from './types'

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const useValidation = (
  value: string,
  isDirty: boolean,
  validations?: IValidations
) => {
  const [isEmpty, setEmpty] = useState<boolean>(true)
  const [minLengthError, setMinLengthError] = useState<boolean>(false)
  const [maxLengthError, setMaxLengthError] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)

  const [errorDescription, setErrorDescription] = useState<string>('')

  const [isInputValid, setIsInputValid] = useState<boolean>(false)

  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case 'isEmpty':
          if (value) {
            setEmpty(false)
            setErrorDescription('')
          } else {
            if (isDirty) {
              setEmpty(true)
              setErrorDescription('The field cannot be empty')
            }
          }
          break

        case 'minLength':
          const minN = Number(validations[validation])
          if (value.length < minN) {
            setMinLengthError(true)
            setErrorDescription(
              `Min field length is ${validations[validation]} ` +
                `${minN > 1 ? 'symbols' : 'symbol'}`
            )
          } else {
            if (isDirty) {
              setMinLengthError(false)
              setErrorDescription('')
            }
          }
          break

        case 'maxLength':
          const maxN = Number(validations[validation])
          if (value.length > maxN) {
            setMaxLengthError(true)
            setErrorDescription(`Max field length is ${validations[validation]} symbols`)
          } else {
            if (isDirty) {
              setMaxLengthError(false)
              setErrorDescription('')
            }
          }
          break

        case 'email':
          if (emailRegExp.test(String(value).toLowerCase())) {
            setEmailError(false)
            setErrorDescription('')
          } else {
            if (isDirty) {
              setEmailError(true)
              setErrorDescription('Wrong email')
            }
          }
          break
      }
    }
  }, [value, validations, isDirty])

  useEffect(() => {
    if (isEmpty || minLengthError || maxLengthError || emailError) setIsInputValid(false)
    else setIsInputValid(true)
  }, [isEmpty, minLengthError, maxLengthError, emailError])

  return {
    isEmpty,
    minLengthError,
    maxLengthError,
    emailError,
    errorDescription,
    isInputValid
  }
}
