import React from "react"
import cn from "classnames"
import { Img } from "../Img"
import "./button-bank.scss"
import { BodySemiboldL } from "../Text"
import { Row } from "../Grid/Row"
import { Column } from "../Grid/Column"

type ButtonBankProps = {
  img: string
  alt?: string
  isSelected?: boolean
  onClick?: () => void
}

export const ButtonBank: React.FC<ButtonBankProps> = ({
  img,
  alt,
  isSelected,
  onClick,
}) => (
  <button
    type="button"
    className={`button-bank button-bank--icon-text ${cn(isSelected ? "button-bank--selected" : "")}`}
    onClick={onClick}
  >
    {img ?
      <Img src={img} size="icon" alt={alt ? alt : "img bank"} />
      :
      null
    }
    {alt ?
      <BodySemiboldL title={(alt.length > 12)? alt : ""} tag="span">{alt}</BodySemiboldL>
      : null
    }
  </button>
)
