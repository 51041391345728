import { BASE_URL } from "../features/invoice/invoiceAPI"
import { languageType } from "../features/invoice/types"

export default class Services {
  static urlCreatePost(lang: languageType, path: string) {
    const processing = lang === "en" ? '/api/processing' : `/${lang}/api/processing`
    const url = BASE_URL + processing + path
    return url
  }
  static urlCreateGet(lang: languageType, path: string, formId: string) {
    const processing = lang === "en" ? '/api/processing' : `/${lang}/api/processing`
    const url = BASE_URL + processing + path + `?id=${formId}`
    return url
  }
  static numberWithoutExp(item: number) {
    const data = String(item).split(/[eE]/);
    if(data.length > 1) {
      if (data.length == 1) return data[0];
  
      let z = '',
        sign = item < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;
  
      if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        return z + str.replace(/^\-/, '');
      }
      mag -= str.length;
      while (mag--) z += '0';
      return str + z;
    }else{
      return String(item)
    }

    }

}