import React from "react";
import { Divider } from "../UI/Divider";
import { IconText } from "../UI/IconText";
import { BodyRegularL, BodyRegularM, BodyRegularXl } from "../UI/Text";
import "./title.scss";

type TitleProps = {
  title: string;
  crypto?: React.ReactNode;
  lead?: string;
  cryptoLead?: string;
};

export const Title: React.FC<TitleProps> = ({
  title,
  crypto,
  lead,
  cryptoLead
}) => {
  return(
  <>
    <div className="title">
      <BodyRegularXl>{title}</BodyRegularXl>

      {lead &&
        <div className="title__lead">
          <BodyRegularL>{lead}</BodyRegularL>

          {crypto &&
            <>
              <IconText gap="lg" sizeIcon="base">
                <BodyRegularM>{cryptoLead}</BodyRegularM>
                {crypto}
              </IconText>
            </>
          }
        </div>
      }
    </div>

    <Divider type="solid" />
  </>
)};