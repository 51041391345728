import React from "react"
import "./icon-text.scss"
import classNames from "classnames"
import { Size } from "../Icon/Icon"

type IconTextProps = {
  children?: React.ReactNode
  gap?: Gap
  align?: Align
  justify?: Justify
  sizeIcon?: SizeIcon
}

type Gap = "esm" | "sm" | "m" | "lg" | "xl" | "2xl"
type Align = "center" | "start" | "end" | "normal"
type Justify = "center" | "normal"
type SizeIcon = Size

export const IconText: React.FC<IconTextProps> = ({
  gap = "m",
  children,
  align = "center",
  justify = "normal",
  sizeIcon = "base",
}) => (
  <div
    className={`icon-text ${classNames(
      gap ? `icon-text--gap-${gap}` : "",
      align ? `icon-text--align-${align}` : "",
      justify ? `icon-text--justify-${justify}` : "",
      sizeIcon ? `icon-text--size-${sizeIcon}` : ""
    )}`}
  >
    {children}
  </div>
)
