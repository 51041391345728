import React, { useState } from 'react'
import { Page } from '../../../../components/Page'
import { BodyRegularM, Button } from '../../../../components/UI'
import { Checkbox } from '../../../../components/UI/Checkbox'
import { Column } from '../../../../components/UI/Grid/Column'
import { Row } from '../../../../components/UI/Grid/Row'
import { VerticalOffset } from '../../../../components/UI/Grid/VerticalOffset'
import { IconArrowLeft, IconCryptoAddress, IconMail } from '../../../../components/UI/Icon'
import { Img } from '../../../../components/UI/Img'
import { Input } from '../../../../components/UI/Input'
import { BodySemiboldM } from '../../../../components/UI/Text'
import { useAppSelector } from '../../../../hooks'
import { useInput } from '../../../../hooks/input'
import { ICurrency } from '../../../../models/ICurrency'
import { languageConstants } from '../../../language/languageSlice'

type StepCryptoDetailsProps = {
  currency: ICurrency
  onNextStep: (currency: ICurrency, address: string) => void
  onBackStep: () => void
}

export const StepCryptoDetails: React.FC<StepCryptoDetailsProps> = ({ currency, onBackStep, onNextStep }) => {
  const langConstants = useAppSelector(languageConstants)
  const [disableNextStep, setDisableNextStep] = useState(true)
  const [checkbox, setCheckbox] = useState(false)
  const email = useInput('', { isEmpty: false, email: true })
  const address = useInput('', { isEmpty: false }, true)

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) setDisableNextStep(false)
    else setDisableNextStep(true)
    email.onChange(e)
  }
  const addressChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) setDisableNextStep(false)
    else setDisableNextStep(true)
    address.onChange(e)
  }

  const checkboxHandler = () => {
    setDisableNextStep(true)
    if (checkbox) {
      email.setDisable(false)
      address.onClear()
      address.setDisable(true)
    } else {
      address.setDisable(false)
      email.onClear()
      email.setDisable(true)
    }
    setCheckbox(!checkbox)
  }

  const nextStepHandler = () => {
    const newAddress = checkbox ? address.value : email.value
    onNextStep(currency, newAddress)
  }

  return (
    <Page
      title={langConstants["L_PAYMENTS_BY_BITCOIN"]}
      lead={langConstants["L_ENTER_DETAILS_FOR_POSSIBLE_REFUND_OR_CHANGE"]}
      width="full"
      crypto={<Img src={currency.linkToIcon} size="icon" alt={currency.name} />}
      cryptoLead={langConstants["L_SELECTED_CURRENCY"]}
    >
      <VerticalOffset offset="level5">
        <Row rowGap="level1">
          <Column col={12}>
            <Input
              name="step-2-email"
              placeholder="E-Mail"
              type="email"
              value={email.value}
              onChange={emailChangeHandler}
              disabled={email.disable}
              icon={{ left: <IconMail /> }}
              label={{ left: langConstants["L_YOUR_EMAIL"] }}
            />
          </Column>
          <Column col={12}>
            <Checkbox
              value="value"
              label={langConstants["L_I_DONT_WANT_TO_SHARE_EMAIL"]}
              name="Checkbox"
              checked={checkbox}
              setCheck={checkboxHandler}
            />
          </Column>
          <Column col={12}>
            <Input
              name="step-2-email"
              placeholder={langConstants["L_ADDRESS"]}
              type="text"
              value={address.value}
              onChange={addressChangeHandler}
              disabled={address.disable}
              icon={{ left: <IconCryptoAddress /> }}
              label={{ left: langConstants["L_CRYPTO_ADDRESS"] }}
            />
          </Column>
        </Row>

        <Row rowGap="level3" columnGap="level3" align="center">
          <Column col={12} order={3} sm={{ col: 'auto', order: 1 }}>
            <Button width="full-mobile" variant="tertiary" onClick={onBackStep}>
              <IconArrowLeft />
              <BodyRegularM>{langConstants["L_CURRENCY_SELECTION"]}</BodyRegularM>
            </Button>
          </Column>

          <Column col={12} order={2} sm={{ col: 'auto', order: 2 }}>
            <Button width="full-mobile" size="medium" onClick={nextStepHandler} disabled={disableNextStep}>
              <BodyRegularM>{langConstants["L_NEXT_STEP"]}</BodyRegularM>
            </Button>
          </Column>
          <Column col={12} order={1} sm={{ col: 6, order: 3 }}>
            <BodySemiboldM color="danger">{langConstants["L_IN_CASE_OF_RETURN_THE_SERVICE_FEE_WILL_NOT_BE_REFUNDED"]}</BodySemiboldM>
          </Column>
        </Row>
      </VerticalOffset>
    </Page>
  )
}
