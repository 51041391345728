import React from "react"
import { Icon, Size } from "./Icon"
import IconArrowLeftBase from "../../../assets/icon/icon--arrow-left.svg"
import IconArrowRightBase from "../../../assets/icon/icon--arrow-right.svg"
import IconCopyBase from "../../../assets/icon/icon--copy.svg"
import IconCryptoAddressBase from "../../../assets/icon/icon--crypto-address.svg"
import IconDropdownBase from "../../../assets/icon/icon--dropdown.svg"
import IconEyeOpenBase from "../../../assets/icon/icon--eye-open.svg"
import IconInfoBase from "../../../assets/icon/icon--info.svg"
import IconMailBase from "../../../assets/icon/icon--mail.svg"
import IconSearchBase from "../../../assets/icon/icon--search.svg"
import IconSendMessageBase from "../../../assets/icon/icon--send-message.svg"
import IconCopiedTwoBase from "../../../assets/icon/icon--copied-two.svg"
import IconMetamaskBase from "../../../assets/icon/icon--metamask.svg"

type IconUIProps = {
  size?: Size
}
export const IconCopiedTwo: React.FC<IconUIProps> = ({ size }) => (
  <Icon icons={IconCopiedTwoBase} name="icon--copied-two" size={size} />
)
export const IconSendMessage: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconSendMessageBase} name="icon--send-message" size={size} />
)
export const IconSearch: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconSearchBase} name="icon--search" size={size} />
)
export const IconMetamask: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconMetamaskBase} name="icon--metamask" size={size} />
)
export const IconMail: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconMailBase} name="icon--mail" size={size} />
)
export const IconInfo: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconInfoBase} name="icon--info" size={size} />
)
export const IconEyeOpen: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconEyeOpenBase} name="icon--eye-open" size={size} />
)
export const IconDropdown: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconDropdownBase} name="icon--dropdown" size={size} />
)
export const IconCryptoAddress: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconCryptoAddressBase} name="icon--crypto-address" size={size} />
)
export const IconCopy: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconCopyBase} name="icon--copy" size={size} />
)
export const IconArrowRight: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconArrowRightBase} name="icon--arrow-right" size={size} />
)
export const IconArrowLeft: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconArrowLeftBase} name="icon--arrow-left" size={size} />
)
