import "./invoce.scss"
import React, { useState, useEffect, useContext } from "react"
import { useParams } from "react-router-dom"
import { setUpd, getStatus, getP2ps, getCurrencies, payCurrency, cancel } from "./invoiceAPI"
import { useAppDispatch } from "../../app/hooks"
import { setStatus } from "./invoiceSlice"
import { Page } from "../../components/Page"
import { StepLoading } from "./Steps/StepLoading"
import { StepFail } from "./Steps/StepFail"
import { StepP2pBank, StepP2pChat, StepP2pEmail } from "./Steps/P2P"
import { StepCryptoCurrency, StepCryptoDetails, StepCryptoPay, StepCryptoSuccess } from "./Steps/Crypto"
import { ICurrency } from "../../models/ICurrency"
import { ParamContext } from "../../context/paramReducer"
import { languageType } from "./types"

type InvoiceParams = {
  formId: string
}
export const Invoice: React.FC = () => {
  const { lang } = useContext(ParamContext)
  const dispatch = useAppDispatch()
  const { formId } = useParams<InvoiceParams>()
  const [error, setError] = useState<string>("")
  const [step, setStep] = useState<React.ReactNode>(<StepLoading />)

  useEffect(() => {
    checkStatus(lang, true)
    const updateStatusInterval = setInterval(() => {
      checkStatus(lang)
    }, 20000);
    return () => {
      clearInterval(updateStatusInterval)
    }
  }, [lang])

  const setStepP2pBank = () => {
    if (!formId) return
    getP2ps(formId, lang).then((data) => {
      setStep(
        <StepP2pBank
          p2ps={data}
          onNextStep={setStepP2pEmail}
          onCrypto={setStepCryptoCurrency}
        />
      )
    })
  }

  const setStepP2pEmail = (updId: number) => {
    setStep(
      <StepP2pEmail
        updId={updId}
        onNextStep={setStepP2pChat}
        onBackStep={setStepP2pBank}
      />
    )
  }

  const setStepP2pChat = (updId: number, email: string) => {
    if (!formId) return
    setUpd(formId, email, updId, lang).then(() => {
      checkStatus(lang)
    })
  }

  const setStepCryptoCurrency = () => {
    if (!formId) return
    getCurrencies(formId, lang).then((data: ICurrency[]) => {
      setStep(
        <StepCryptoCurrency
          onNextStep={setStepCryptoDetails}
          // onP2p={setStepP2pBank}
          currencies={data}
        />
      )
    })
  }

  const setStepCryptoDetails = (currency: ICurrency) => {
    setStep(
      <StepCryptoDetails
        currency={currency}
        onNextStep={setStepCryptoPay}
        onBackStep={setStepCryptoCurrency}
      />
    )
  }
  const onCancel = () => {
    if (!formId) return
    cancel(formId, lang)
    .then(() => checkStatus(lang))
  }

  const setStepCryptoPay = (currency: ICurrency, address: string) => {
    if (!formId) return
    payCurrency(formId, currency.id, address, lang).then((data) => {
      checkStatus(lang)
      setStep(<StepCryptoPay data={data.data} paymentCurrency={currency} payCancel={onCancel} />)
    })
  }

  const checkStatus = (language: languageType, first: boolean = false) => {
    if (!formId) return
    getStatus(formId, language)
      .then((data) => {
        dispatch(setStatus(data))
        if (data.status === 0 || (data.result.type === "error" && data.invoice.status !== 1)) {
          setStep(<StepFail message={data.result.message} />)
        }else if (data.result.type === "success" || data.invoice.status === 4){
          setStep(<StepCryptoSuccess shopUrl={data.successUrl}/>)
        } else {
          if (data.p2pOrder) {
            setStep(<StepP2pChat formId={formId} checkStatus={checkStatus} />)
          } else if (data.paymentCurrency) {
            setStep(<StepCryptoPay data={data.invoice} paymentCurrency={data.paymentCurrency} payCancel={onCancel} />)
          } else if (first) {
            // setStepP2pBank()
            setStepCryptoCurrency()
          }
        }
      })
      .catch((e) => {
        setError(e.toString())
      })
  }

  if (error) {
    return (
      <Page title="Error">
        <div dangerouslySetInnerHTML={{ __html: error }} />
      </Page>
    )
  }

  return <>{step}</>
}
