import React, { useEffect, useState } from "react"
import { Page } from "../../../../components/Page"
import { BodyRegularM } from "../../../../components/UI"
import { Button } from "../../../../components/UI/Button"
import { ButtonBank } from "../../../../components/UI/ButtonBank"
import { Column } from "../../../../components/UI/Grid/Column"
import { Row } from "../../../../components/UI/Grid/Row"
import { VerticalOffset } from "../../../../components/UI/Grid/VerticalOffset"
import { IconSearch } from "../../../../components/UI/Icon"
import { IconMetamask } from "../../../../components/UI/Icon/Icons"
import { Input } from "../../../../components/UI/Input"
import { BanksOrCryptoList } from "../../../../components/UI/Layout/BanksOrCryptoList"
import { useAppSelector } from "../../../../hooks"
import { useInput } from "../../../../hooks/input"
import { ICurrency } from "../../../../models/ICurrency"
import { languageConstants } from "../../../language/languageSlice"

type StepCryptoCurrencyProps = {
  onNextStep: (item: ICurrency) => void
  // onP2p: () => void
  currencies: ICurrency[]
}
export const StepCryptoCurrency: React.FC<StepCryptoCurrencyProps> = ({
  onNextStep,
  // onP2p,
  currencies,
}) => {
  const langConstants = useAppSelector(languageConstants)
  const [currencyId, setCurrencyId] = useState<ICurrency | null>(null)
  const [showAll, setShowAll] = useState(false)
  const [disableNextStep, setDisableNextStep] = useState(true)
  const [currenciesShow, setCurrenciesShow] = useState(currencies)
  const search = useInput()

  const showAllHandler = () => {
    setShowAll(!showAll)
  }
  const nextStepHandler = () => {
    if (currencyId) onNextStep(currencyId)
  }

  const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      const newCurrenciesList = currencies.filter(el => el.name.toLowerCase().includes(search.value.toLowerCase()))
      setCurrenciesShow(newCurrenciesList)
    } else {
      setCurrenciesShow(currencies)
    }
    search.onChange(e)
  }

  useEffect(() => {
    if (currencyId) setDisableNextStep(false)
    else setDisableNextStep(true)
  }, [currencyId])

  return (
    <Page title={langConstants["L_PAYMENTS_BY_BITCOIN"]} lead={langConstants["L_CHOOSE_CURRENCY"]}>
      <VerticalOffset offset="level3">
        <Input
          name="step-2-email"
          value={search.value}
          onChange={searchChange}
          placeholder={langConstants["L_SEARCH"]}
          type="text"
          icon={{ left: <IconSearch /> }}
        />
        <BanksOrCryptoList>
          {currenciesShow.map((el, i) => {
            const click = () => {
              if (currencyId?.id === el.id) setCurrencyId(null)
              else setCurrencyId(el)
            }
            if (showAll || i < 12) {
              return (
                <ButtonBank
                  img={el.linkToIcon}
                  key={el.id}
                  alt={el.name}
                  onClick={click}
                  isSelected={currencyId?.id === el.id ? true : false}
                />
              )
            } else {
              return null
            }
          })}
        </BanksOrCryptoList>
        {currencies.length > 12 ?
          <Button width="full-mobile" size="medium" variant="secondary" onClick={showAllHandler}>
            {showAll ?
              <BodyRegularM>{langConstants["L_HIDE"]}</BodyRegularM>
              :
              <BodyRegularM>{langConstants["L_SHOW_ALL"]}</BodyRegularM>
            }
          </Button>
          :
          null}

        <Row rowGap="level1" columnGap="level1" align="center">
          <Column col={12} sm={{ col: "auto" }}>
            <Button width="full-mobile" size="medium" onClick={nextStepHandler} disabled={disableNextStep}>
              <BodyRegularM>{langConstants["L_NEXT_STEP"]}</BodyRegularM>
            </Button>
          </Column>

          {/* <Column col={12} sm={{ col: "auto" }}>
          <Button width="full-mobile" variant="tertiary" disabled>
            <IconMetamask />
            <BodyRegularM>{langConstants["L_PAY_VIA_METAMASK"]}</BodyRegularM>
          </Button>
        </Column> */}

          {/* <Column col={12} sm={{ col: "auto" }}>
            <Button width="full-mobile" variant="tertiary" onClick={onP2p}>
              <BodyRegularM>{langConstants["L_OR_PAY_BY_P2P"]}</BodyRegularM>
            </Button>
          </Column> */}
        </Row>
      </VerticalOffset>
    </Page>
  )
}
