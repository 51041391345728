import React from "react";
import "./qr-code.scss";
import { QRCodeSVG } from "qrcode.react";

type QrCodeProps = {
value: string
};

export const QrCode: React.FC<QrCodeProps> = ({value}) => (
  <div className="qr-code">
    <QRCodeSVG value={value} />,
  </div>
);