import React from 'react';
import { Column } from '../UI/Grid/Column';
import { Container } from '../UI/Grid/Container/Container';
import { Row } from '../UI/Grid/Row';
import { Link } from '../UI/Link';
import { BodyRegularM } from '../UI/Text';
import "./footer.scss";

type FooterProps = {
}

export const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className="footer">
      <Container>
        <Row justify="center">
          <Column col={"auto"}>
            <Link href="payment@hashbon.com" type="mailto">
              <BodyRegularM>payment@hashbon.com</BodyRegularM>
            </Link>
          </Column>
        </Row>
      </Container>
    </footer>
  );
};
