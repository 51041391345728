import React from 'react';
import { Button } from '../UI/Button';
import { FlagDropdown } from '../UI/FlagDropdown';
import { Logo } from '../UI/Logo';
import { BodyRegularM } from '../UI/Text';
import "./header.scss";

type HeaderProps = {
}

export const Header: React.FC<HeaderProps> = () => {
  return (
    <header className="header">
      <div className="header__left">
        <Logo />
      </div>

      <div className="header__right">
        <div className="header__actions">
          <FlagDropdown/>

          {/*<Button size="small">*/}
          {/*  <BodyRegularM>Sign in</BodyRegularM>*/}
          {/*</Button>*/}
        </div>
      </div>
    </header>
  );
};
