import React from "react"
import "./chat-list.scss"
import classNames from "classnames"
import { BodyRegularM } from "../../../UI/Text"
import { Avatar } from "../../../UI/Avatar"

type ChatListProps = {
  children: React.ReactNode
}

export const ChatList: React.FC<ChatListProps> = ({ children }) => (
  <div className="chat-list">
    <ul className="chat-list__body">{children}</ul>
  </div>
)

type ChatListItemProps = {
  message: string
  type: "in" | "out"
}

export const ChatListItem: React.FC<ChatListItemProps> = ({ message, type }) => {
  return (
    <li
      className={`chat-list__item ${classNames(
        type === "in" ? "chat-list__item--support" : "chat-list__item--user"
      )}`}
    >
      {type === "in" ? (
        <Avatar color="green" name="SP" size="m" />
      ) : (
        <Avatar color="blue" name="LK" size="m" />
      )}
      <div
        className={`chat-list__item-message ${classNames(
          type === "in"
            ? "chat-list__item-message--support"
            : "chat-list__item-message--user"
        )}`}
      >
        <BodyRegularM>{message}</BodyRegularM>
      </div>
    </li>
  )
}
