import React from "react"
import classNames from "classnames"
import "./column.scss"

type ColumnProps = {
  children: React.ReactNode
  col?: Col
  order?: Order
  alignSelf?: AlignSelf
  sm?: {
    col?: Col
    order?: Order
    alignSelf?: AlignSelf
  }
  md?: {
    col?: Col
    order?: Order
    alignSelf?: AlignSelf
  }
  lg?: {
    col?: Col
    order?: Order
    alignSelf?: AlignSelf
  }
  xl?: {
    col?: Col
    order?: Order
    alignSelf?: AlignSelf
  }
  xxl?: {
    col?: Col
    order?: Order
    alignSelf?: AlignSelf
  }
}

type Col = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto"
type AlignSelf =
  | "auto"
  | "normal"
  | "center"
  | "start"
  | "end"
  | "self-start"
  | "self-end"
  | "baseline"
  | "stretch"
  | "inherit"
  | "initial"
  | "unset"
type Order = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export const Column: React.FC<ColumnProps> = ({
  children,
  col,
  alignSelf,
  order,
  sm,
  md,
  lg,
  xl,
  xxl,
}) => {
  return (
    <div
      className={`column ${classNames(
        col ? `column--col-${col}` : "",
        order ? `column--order-${order}` : "",
        alignSelf ? `column--align-self-${alignSelf}` : "",
        sm?.col ? `column--sm-col-${sm?.col}` : "",
        sm?.order ? `column--sm-order-${sm?.order}` : "",
        sm?.alignSelf ? `column--sm-align-self-${sm?.alignSelf}` : "",
        md?.col ? `column--md-col-${md?.col}` : "",
        md?.order ? `column--md-order-${md?.order}` : "",
        md?.alignSelf ? `column--md-align-self-${md?.alignSelf}` : "",
        lg?.col ? `column--lg-col-${lg?.col}` : "",
        lg?.order ? `column--lg-order-${lg?.order}` : "",
        lg?.alignSelf ? `column--lg-align-self-${lg?.alignSelf}` : "",
        xl?.col ? `column--xl-col-${xl?.col}` : "",
        xl?.order ? `column--xl-order-${xl?.order}` : "",
        xl?.alignSelf ? `column--xl-align-self-${xl?.alignSelf}` : "",
        xxl?.col ? `column--xxl-col-${xxl?.col}` : "",
        xxl?.order ? `column--xxl-order-${xxl?.order}` : "",
        xxl?.alignSelf ? `column--xxl-align-self-${xxl?.alignSelf}` : ""
      )}
		`}
    >
      {children}
    </div>
  )
}
