import React from "react";
import "./icon.scss";
import classNames from "classnames";

export type IconProps = {
  icons: string;
  name: string;
  size?: Size;
  stroke?: Stroke;
  fill?: Fill;
};

type Stroke = "none" | "white" | "primary";
type Fill = "white" | "none";

export type Size = "base" | "esm" | "sm" | "md" | "lg" | "xl";

export const Icon: React.FC<IconProps> = ({
  icons,
  name,
  size = "base",
  stroke,
  fill,
}) => (
  <svg
    className={`icon ${classNames(
      name,
      size ? `icon--size-${size}` : "",
      stroke ? `icon--stroke-${stroke}` : "",
      fill ? `icon--fill-${fill}` : "",
    )}`}
  >
    <use xlinkHref={`${icons}#${name}`} />
  </svg>
);

type IconListnProps = {
  items: {
    icon: React.ReactNode;
    name: string,
  }[]
}

export const IconList: React.FC<IconListnProps> = ({
  items = [],

}) => {
  return (
    <ul className="icon__list">
      {items.map((item, index) => (
        <li className="icon__list-item" key={index}>
          {item.icon}
          <span className="icon__list-item-name">{item.name}</span>
        </li>
      ))}
    </ul>
  )
}