import React, { useContext, useEffect, useState } from "react";
import "./flag-dropdown.scss";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { IconDropdown } from "../Icon";
import { IconFlagCz, IconFlagDe, IconFlagEng, IconFlagEs, IconFlagRus, IconFlagFr, IconFlagAr, IconFlagFa, IconFlagBr, IconFlagTr } from "../IconImg";
import { languageType } from "../../../features/invoice/types";
import { ParamContext } from "../../../context/paramReducer";

type FlagDropdownProps = {
  // lang: languageType
};

type FlagDropdowns = {
  value: "RU" | "ENG" | "DE" | "CZ" | "ES" | "FR" | "AR" | "FA" | "PT_BR" | "TR";
  type: languageType
  label: React.ReactNode
}

const optionLangs: FlagDropdowns[] = [
  {
    value: "ENG",
    type: "en",
    label: <IconFlagEng />
  },
  {
    value: "RU",
    type: "ru",
    label: <IconFlagRus />
  },
  {
    value: "CZ",
    type: "cs",
    label: <IconFlagCz />
  },
  {
    value: "DE",
    type: "de",
    label: <IconFlagDe />
  },
  {
    value: "ES",
    type: "es",
    label: <IconFlagEs />
  },
  {
    value: "FR",
    type: "fr",
    label: <IconFlagFr />
  },
  {
    value: "AR",
    type: "ar",
    label: <IconFlagAr />
  },
  {
    value: "FA",
    type: "fa",
    label: <IconFlagFa />
  },
  {
    value: "PT_BR",
    type: "pt-br",
    label: <IconFlagBr />
  },
  {
    value: "TR",
    type: "tr",
    label: <IconFlagTr />
  },
]

export const FlagDropdown: React.FC<FlagDropdownProps> = () => {
  const { lang, setLang } = useContext(ParamContext)
  const defaultValue = optionLangs.find(el => el.type === lang)
  const [value, setValue] = useState(defaultValue ? defaultValue : optionLangs[0])

  useEffect(() => {
    const newValue = optionLangs.find(el => el.type === lang)
    if (newValue) setValue(newValue)
  }, [lang])

  const langHandler = (item: FlagDropdowns) => {
    setValue(item)
    setLang(item.type)
  }

  return (
    <div className="flag-dropdown">
      <Select
        isSearchable={false}
        className="flag-dropdown__body"
        options={optionLangs}
        classNamePrefix="flag-dropdown"
        defaultValue={value}
        components={{ DropdownIndicator }}
        onChange={(value) => langHandler(value as FlagDropdowns)}
      />
    </div>
  )
};

export const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => (
  <components.DropdownIndicator {...props}>
    <IconDropdown size="esm" />
  </components.DropdownIndicator>
)