import React from "react";
import "./text.scss";
import classNames from "classnames";


type TextUIProps = {
  color?: Colors;
  children: React.ReactNode;
};

type TextIProps = {
  color?: Colors;
  children: React.ReactNode;
  tag?: "div" | "p" | "span";
  title?: string
};

export const H1: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="h1" color={color} tag="H1">
    {children}
  </Text>
);
export const H2: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="h2" color={color} tag="H2">
    {children}
  </Text>
);
export const H3: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="h3" color={color} tag="H3">
    {children}
  </Text>
);
export const H4: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="h4" color={color} tag="H3">
    {children}
  </Text>
);
export const BodyRegularXl: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="body-regular-xl" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodyRegularL: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="body-regular-l" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodySemiboldL: React.FC<TextIProps> = ({ color, children, tag, title="" }) => (
  <Text variant="body-semibold-l" title={title} color={color} tag={tag}>
    {children}
  </Text>
);
export const BodyRegularM: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="body-regular-m" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodySemiboldM: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="body-semibold-m" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodySemiboldXs: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="body-semibold-xs" color={color} tag={tag}>
    {children}
  </Text>
);
export const AvatarTextS: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="avatar-s" color={color} tag={tag}>
    {children}
  </Text>
);
export const AvatarTextM: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="avatar-m" color={color} tag={tag}>
    {children}
  </Text>
);
export const AvatarTextL: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="avatar-l" color={color} tag={tag}>
    {children}
  </Text>
);

type TextProps = {
  variant?: Variant;
  color?: Colors;
  children: React.ReactNode;
  tag?: Tag;
  title?: string
};

type Tag = "H1" | "H2" | "H3" | "H4" | "div" | "p" | "span";

type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "body-regular-xl"
  | "body-regular-l"
  | "body-semibold-l"
  | "body-regular-m"
  | "body-semibold-m"
  | "body-semibold-xs"
  | "avatar-s"
  | "avatar-m"
  | "avatar-l"

type Colors = "primary" | "secondary" | "third" | "four" | "danger";

const Text: React.FC<TextProps> = ({
  variant,
  color = "primary",
  tag = "p",
  children,
  title=""
}) => (
  <>
    {tag === "H1" && (
      <h1
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title}
      >
        {children}
      </h1>
    )}
    {tag === "H2" && (
      <h2
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title}
      >
        {children}
      </h2>
    )}
    {tag === "H3" && (
      <h3
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title}
      >
        {children}
      </h3>
    )}
    {tag === "H4" && (
      <h4
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title}
      >
        {children}
      </h4>
    )}
    {tag === "div" && (
      <div
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title}
      >
        {children}
      </div>
    )}
    {tag === "p" && (
      <p
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title}
      >
        {children}
      </p>
    )}
    {tag === "span" && (
      <span
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title}
      >
        {children}
      </span>
    )}
  </>
);
