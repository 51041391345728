import React from "react";
import classNames from "classnames";
import "./mark.scss";

type MarkProps = {
  mark?: Marks;
  children: React.ReactNode;
};

type Marks = "primary";

export const Mark: React.FC<MarkProps> = ({ mark, children }) => (
  <span className={`mark ${classNames(mark ? `mark--${mark}` : "")}`}>
    {children}
  </span>
);
