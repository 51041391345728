import React from "react"
import "./avatar.scss"
import cn from "classnames"
import { AvatarTextL, AvatarTextM, AvatarTextS } from "../Text"

type AvatarProps = {
  name: string
  size?: Size
  color?: Color
}

type Size = "s" | "m" | "l"
type Color = "blue" | "orange" | "yellow" | "red" | "green"

export const Avatar: React.FC<AvatarProps> = ({ size = "m", name, color }) => (
  <div
    className={`avatar ${cn(
      size ? `avatar--size-${size}` : "",
      color ? `avatar--color-${color}` : ""
    )}`}
  >
    {size === "s" && (
      <AvatarTextS color="third" tag="span">
        {name}
      </AvatarTextS>
    )}
    {size === "m" && (
      <AvatarTextM color="third" tag="span">
        {name}
      </AvatarTextM>
    )}
    {size === "l" && (
      <AvatarTextL color="third" tag="span">
        {name}
      </AvatarTextL>
    )}
  </div>
)
