import React, {KeyboardEventHandler, useRef} from "react";
import { Button } from "../../../UI/Button";
import { IconSendMessage } from "../../../UI/Icon";
import "./chat-text-area.scss";

type ChatTextAreaProps = {
  onSubmit: (message: string) => void
};

export const ChatTextArea: React.FC<ChatTextAreaProps> = ({onSubmit}) => {
  const textField = useRef<HTMLTextAreaElement>(null);
  const onClick = () => {
    if (!textField.current) return
    onSubmit(textField.current.value)
    textField.current.value = ''
  }
  const onEnterPress = (e: any) => {
    if(e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      onClick()
    }
  }
  return (
    <div className="chat-text-area">
      <div className="chat-text-area__body">
        <textarea
          ref={textField}
          className="chat-text-area__area"
          id="massage" name="message"
          placeholder="Enter your message"
          onKeyDown={onEnterPress}
        >
        </textarea>
      </div>

      <Button variant="only-icon" onClick={onClick}>
        <IconSendMessage />
      </Button>
    </div>
  )
};