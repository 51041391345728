import React, { useContext, useEffect } from 'react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { Wrapper } from '../components/Wrapper'
import { ParamContext } from '../context/paramReducer'
import { getLanguageConstants } from '../features/language/languageAPI'
import { updateConstants } from '../features/language/languageSlice'
import { useAppDispatch } from '../hooks'

type PageMainProps = {
  children: React.ReactNode;
};

export const PageMain: React.FC<PageMainProps> = ({
  children
}) => {
  const dispatch = useAppDispatch()
  const { lang } = useContext(ParamContext)

  useEffect(() => {
    getLanguageConstants(lang)
      .then(res => dispatch(updateConstants(res.message)))
  }, [lang])

  return (
    <Wrapper>
      <Header />
      <Main>
        {children}
      </Main>
      <Footer />
    </Wrapper>
  )
}
