import React from "react";
import { BodyRegularM } from "../Text";
import "./badge.scss";

type BadgeProps = {
  text: string;
};

export const Badge: React.FC<BadgeProps> = ({
  text,
}) => (
  <div className="badge">
    <BodyRegularM color="secondary">{text}</BodyRegularM>
  </div>
);
