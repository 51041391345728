import React from "react"
import "./banks-or-crypto-list.scss"

type BanksOrCryptoListProps = {
  children: React.ReactNode
}

export const BanksOrCryptoList: React.FC<BanksOrCryptoListProps> = ({ children }) => (
  <div className="banks-or-crypto-list">{children}</div>
)
