import React from "react";
import classNames from "classnames";
import "./vertical-offset.scss";

type VerticalOffsetProps = {
  offset?: OffsetSize;
  children?: React.ReactNode;
};

type OffsetSize =
  | "level1"
  | "level2"
  | "level3"
  | "level4"
  | "level5"
  | "level6"
  | "level7"
  | "level8"
  | "level9"
  | "level10"

export const VerticalOffset: React.FC<VerticalOffsetProps> = ({
  offset,
  children,
}) => {
  return (
    <div
      className={`vertical-offset ${classNames(
        `vertical-offset--${offset}`,
      )}`}
    >
      {children}
    </div>
  );
};
