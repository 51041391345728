import {IStatus} from "./types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";

export interface IInvoiceState {
  status?: IStatus;
}

const initialState: IInvoiceState = {
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
  },
});

export const { setStatus } = invoiceSlice.actions;
export const selectStatus = (state: RootState) => state.invoice.status;
export default invoiceSlice.reducer;
