import React, { useEffect, useRef } from "react"
import "./chat.scss"
import { ChatList, ChatListItem } from "./components/ChatList"
import { ChatTextArea } from "./components/ChatTextArea"

interface IMessage {
  type: "in" | "out"
  message: string
}

type ChatProps = {
  messages?: IMessage[]
  onSubmit: (message: string) => void
}

export const Chat: React.FC<ChatProps> = ({ messages, onSubmit }) => {
  const bodyListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (messages && messages?.length >= 4 && null !== bodyListRef.current) {
      bodyListRef.current.scrollTop = bodyListRef.current.scrollHeight
    }
  }, [messages])

  return (
    <div className="chat">
      <div className="chat__body">
        <div className="chat__body-wrapper">
          <div className="chat__body-list" ref={bodyListRef}>
            <ChatList>
              {messages &&
                messages.map((m, i) => (
                  <ChatListItem key={`message-${i}`} message={m.message} type={m.type} />
                ))}
            </ChatList>
          </div>
        </div>

        <div className="chat__actions">
          <ChatTextArea onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  )
}
