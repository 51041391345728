import React from 'react'
import { isString } from 'lodash'
import { useState } from 'react'
import { IValidations } from './types'
import { useValidation } from './useValidation'

// how to use
// const email = useInput("", { isEmpty: true, email: true });

export const useInput = (initialValue = '', validations?: IValidations, initialDisable:boolean = false) => {
  const [value, setValue] = useState<string>(initialValue)
  const [isDirty, setDirty] = useState<boolean>(false)
  const [disable, setDisable] = useState(initialDisable)
  const valid = useValidation(value, isDirty, validations)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
  ) => (isString(e) ? setValue(e) : setValue(e.target.value))

  const handleBlur = () => setDirty(true)

  const handleClear = () => {
    setValue('')
    setDirty(false)
  }

  return {
    value,
    isDirty,
    disable,
    onChange: handleChange,
    onBlur: handleBlur,
    onClear: handleClear,
    setDisable,
    ...valid,
  }
}
