export default class TimeUpdate {
  static secondsToHms (d:number = 0) {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor(d % 3600 / 60)
    const s = Math.floor(d % 3600 % 60)

    const hDisplay = h > 0 ? this.plusZero(h) + ':' : ''
    const mDisplay = m > 0 ? this.plusZero(m) + ':' : '00:'
    const sDisplay = s > 0 ? this.plusZero(s) : '00'
    return hDisplay + mDisplay + sDisplay
  }

  static plusZero (item: number) {
    if (item < 10) {
      return `0${item}`
    } else {
      return `${item}`
    }
  }
}
