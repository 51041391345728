import React from "react";
import { IconErrorS, IconLoaderSmall } from "../IconImg";
import { IconText } from "../IconText";
import { Loader } from "../Loader";
import { BodyRegularM, BodySemiboldM } from "../Text";
import "./table-data.scss";

type TableDataProps = {
  children: React.ReactNode;
};

export const TableData: React.FC<TableDataProps> = ({
  children,
}) => (
  <div className="table-data">
    {children}
  </div>
);

type TableDataItemProps = {
  name: string;
  value: {
    text: string;
    color?: "primary" | "secondary" | "third" | "four" | "danger";
  }
};

export const TableDataItem: React.FC<TableDataItemProps> = ({
  name,
  value = {
    text: "",
    color: "primary"
  },
}) => (
  <div className="table-data__item">
    <div className="table-data__item-name">
      <BodySemiboldM color="four">
        {name}:
      </BodySemiboldM>
    </div>

    <div className="table-data__item-value">
      <BodyRegularM color={value.color}>{value.text}</BodyRegularM>
    </div>
  </div>
);

type TablePaymentStatusProps = {
  status?: "waiting" | "error" | "ok" | "success";
  message: string
};

export const TablePaymentStatus: React.FC<TablePaymentStatusProps> = ({
  status,
  message
}) => (
  <div className="table-data__status">
    {status === "waiting" || status === "ok" && (
      <IconText gap="m">
        <Loader loader={<IconLoaderSmall size="sm" />} />
        <BodyRegularM >
          {message}
        </BodyRegularM>
      </IconText>
    )}

    {status === "error" && (
      <IconText sizeIcon="sm" gap="m">
        <IconErrorS />
        <BodyRegularM color="danger">
          Warning! You have not paid the full amount
        </BodyRegularM>
      </IconText>
    )}
  </div>
);