import React from 'react'
import './input.scss'
import classNames from 'classnames'
import cn from 'classnames'
import { BodyRegularM } from '../Text'
import { IconText } from '../IconText'
import { IconInfo } from '../Icon'

type InputProps = {
  variant?: Variant
  name: string
  type: InputType
  value?: string
  placeholder?: string
  disabled?: boolean
  label?: {
    left?: string
    right?: React.ReactNode
  }
  error?: string
  maxLength?: number
  icon?: {
    left?: React.ReactNode
    right?: React.ReactNode
  }
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  required?: boolean
  autoComplete?: 'on' | 'off'
}

type Variant = 'without'
type InputType = 'text' | 'tel' | 'number' | 'email' | 'password'

export const Input: React.FC<InputProps> = ({
  variant,
  name,
  type = 'text',
  value = '',
  placeholder = 'Поле ввода',
  disabled = false,
  label,
  error = '',
  maxLength,
  icon,
  onChange,
  onBlur,
  required,
  autoComplete = 'off',
}) => {

  return (
    <div
      className={`input ${classNames(
        error ? 'input--error' : '',
        variant ? `input--${variant}` : ''
      )}`}
      onClick={onBlur}
    >
      {label && (
        <label htmlFor={name} className="input__label">
          <BodyRegularM>{label?.left}</BodyRegularM>
          {label?.right}
        </label>
      )}

      <div
        className={cn('input__body', { 'input__body--disabled': disabled })}
        tabIndex={1}
      >
        {icon?.left && <div className="input__icon">{icon.left}</div>}

        <input
          className="input__input"
          type={type}
          name={name}
          id={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          autoComplete={autoComplete}
        />

        {icon?.right && <div className="input__icon">{icon.right}</div>}
      </div>

      {error && (
        <div className="input__error">
          <IconText sizeIcon="sm">
            <IconInfo />
            <BodyRegularM color="danger">{error}</BodyRegularM>
          </IconText>
        </div>
      )}
    </div>
  )
}
