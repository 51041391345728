import React from "react"
import classNames from "classnames"
import "./row.scss"

type RowProps = {
  children?: React.ReactNode
  align?: Align
  justify?: Justify
  direction?: Direction
  rowGap?: RowGap
  columnGap?: ColumnGap
  noGutters?: boolean
  md?: {
    direction?: Direction
    align?: Align
    justify?: Justify
  }
  lg?: {
    direction?: Direction
    align?: Align
    justify?: Justify
  }
  xl?: {
    direction?: Direction
    align?: Align
    justify?: Justify
  }
  xxl?: {
    direction?: Direction
    align?: Align
    justify?: Justify
  }
}

type Align = "center" | "flex-end" | "flex-start" | "stretch"

type Justify = "center" | "flex-end" | "flex-start" | "space-between" | "space-around"

type RowGap =
  | "level1"
  | "level2"
  | "level3"
  | "level4"
  | "level5"
  | "level6"
  | "level7"
  | "level8"
  | "level9"
  | "level10"

type ColumnGap =
  | "level1"
  | "level2"
  | "level3"
  | "level4"
  | "level5"
  | "level6"
  | "level7"
  | "level8"
  | "level9"
  | "level10"

type Direction = "row" | "column" | "row-reverse" | "column-reverse"

export const Row: React.FC<RowProps> = ({
  align,
  justify,
  direction,
  children,
  rowGap,
  columnGap,
  md,
  lg,
  xl,
  xxl,
  noGutters = false,
}) => {
  return (
    <div
      className={`row ${classNames(
        direction ? `row--${direction}` : "",
        noGutters ? "row--no-gutters" : "",
        align ? `row--align-${align}` : "",
        justify ? `row--justify-${justify}` : "",
        rowGap ? `row--row-gap--${rowGap}` : "",
        columnGap ? `column--column-gap--${columnGap}` : "",
        md?.direction ? `row--md-${md.direction}` : "",
        md?.align ? `row--md-align-${md.align}` : "",
        md?.justify ? `row--md-justify-${md.justify}` : "",
        lg?.direction ? `row--lg-${lg.direction}` : "",
        lg?.align ? `row--lg-align-${lg.align}` : "",
        lg?.justify ? `row--lg-justify-${lg.justify}` : "",
        xl?.direction ? `row--xl-${xl.direction}` : "",
        xl?.align ? `row--xl-align-${xl.align}` : "",
        xl?.justify ? `row--xl-justify-${xl.justify}` : "",
        xxl?.direction ? `row--xxl-${xxl.direction}` : "",
        xxl?.align ? `row--xxl-align-${xxl.align}` : "",
        xxl?.justify ? `row--xxl-justify-${xxl.justify}` : ""
      )}`}
    >
      {children}
    </div>
  )
}
