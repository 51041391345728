import axios from "axios";
import { APP_ENV } from "../../app/enviroment";
import { ICurrency } from "../../models/ICurrency";
import { IPayDataRequest } from "../../models/IPayCurrencyDataRequest";
import Services from "../../utils/services";
import { languageType } from "./types";

export const BASE_URL = APP_ENV.REACT_APP_API_URL
export const BASE_API_URL = BASE_URL + '/api/processing'

export const getStatus = (formId: string, lang: languageType) => 
  axios.get(Services.urlCreateGet(lang, "/new-simple-check-status", formId))
    .then((result) => {
      if (result.data.type === 'error') {
        throw new Error(result.data.text)
      }
      return result.data
    })

export const getP2ps = async(formId: string, lang: languageType) =>
  axios.get(Services.urlCreateGet(lang, "/simple-invoices/get-p2ps", formId))
    .then((result) => {
      if (result.data.type === 'error') {
        throw new Error(result.data.text)
      }
      return result.data.data
    })

interface IGetCurrenciesRequest {
  data: ICurrency[]
  type: string
  text: string
}

export const getCurrencies = async(formId: string, lang: languageType) =>
  axios.get<IGetCurrenciesRequest>(Services.urlCreateGet(lang, "/simple-invoices/get-available-currencies", formId))
  .then((result) => {
    if (result.data.type === 'error') {
      throw new Error(result.data.text)
    }
    return result.data.data
  })


export const setUpd = (formId: string, email: string, updId: number, lang: languageType) => {
  const data = new FormData()
  data.append('id', formId)
  data.append('email', email)
  data.append('updId', updId.toString())
  const url = Services.urlCreatePost(lang, "/simple-invoices/set-p2p")
  return axios.post(url, data)
    .then((result) => {
      if (result.data.type === 'error') {
        throw new Error(result.data.text)
      }
      return result.data
    })
}

export const setPaid = (formId: string, lang: languageType) => {
  const data = new FormData()
  data.append('id', formId)
  const url = Services.urlCreatePost(lang, "/simple-invoices/set-p2p-to-paid")
  return axios.post(url, data)
    .then((result) => {
      if (result.data.type === 'error') {
        throw new Error(result.data.text)
      }
      return result.data
    })
}

export const cancel = (formId: string, lang: languageType) => {
  const data = new FormData()
  data.append('id', formId)
  const url = Services.urlCreatePost(lang, "/cancel-simple")
  return axios.post(url, data)
    .then((result) => {
      if (result.data.type === 'error') {
        throw new Error(result.data.text)
      }
      return result.data
    });
}

export const sendMessage = (formId: string, message: string, lang: languageType) => {
  const data = new FormData()
  data.append('id', formId)
  data.append('message', message)
  const url = Services.urlCreatePost(lang, "/simple-invoices/send-p2p-chat-message")
  return axios.post(url, data)
    .then((result) => {
      if (result.data.type === 'error') {
        throw new Error(result.data.text)
      }
      return result.data
    })
}
interface IPayCurrencyRequest {
  data: IPayDataRequest
  type: string
  text: string
}

export const payCurrency = (formId: string, currencyId: number, address: string, lang: languageType) => {
  const data = new FormData()
  data.append('id', formId)
  data.append('currencyId', `${currencyId}`)
  data.append('address', address)
  const url = Services.urlCreatePost(lang, "/simple-invoices/set-pay-currency")
  return axios.post<IPayCurrencyRequest>(url, data)
    .then((result) => {
      if (result.data.type === 'error') {
        throw new Error(result.data.text)
      }
      return result.data
    })
}
