import React from "react";
import "./img.scss";
import classNames from "classnames";

type ImgProps = {
  src: string;
  alt: string;
  size?: Size;
};

type Size = "auto" | "full" | "logo" | "icon";

export const Img: React.FC<ImgProps> = ({
  src,
  alt,
  size,
}) => (
  <>
    {src ?
      <img className={`img ${classNames(size ? `img--size-${size}` : "")}`} src={src} alt={alt} />
      :
      <span>{alt}</span>}
  </>
);

type ImgListProps = {
  items: {
    src: string;
    alt: string;
    size?: Size;
  }[]
}

export const ImgList: React.FC<ImgListProps> = ({
  items = [],
}) => {
  return (
    <ul className="img__list">
      {items.map((item, index) => (
        <li className="img__list-item" key={index}>
          <Img src={item.src} alt={item.alt} size={item.size} />
        </li>
      ))}
    </ul>
  )
}