import { createContext } from 'react'
import { languageType } from '../features/invoice/types'

type stateType = {
    lang: languageType,
    setLang: (c: languageType) => void,
}

const initialState: stateType = {
    lang: "en",
    setLang: () => { },
}
export const ParamContext = createContext(initialState)
