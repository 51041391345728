import React from "react";
import classNames from "classnames";
import "./container.scss";

type ContainerProps = {
  size?: Size;
  children: React.ReactNode;
  md?: {
    size?: Size;
  };
  lg?: {
    size?: Size;
  };
  xl?: {
    size?: Size;
  };
  xxl?: {
    size?: Size;
  };
};
type Size = "fluid" | "base";

export const Container: React.FC<ContainerProps> = ({
  children,
  size,
  md,
  lg,
  xl,
  xxl,
}) => {
  return (
    <div
      className={`container ${classNames(
        size ? `container--${size}` : "",
        md ? `container--md-${md.size}` : "",
        lg ? `container--lg-${lg.size}` : "",
        xl ? `container--xl-${xl.size}` : "",
        xxl ? `container--xxl-${xxl.size}` : ""
      )}`}
    >
      {children}
    </div>
  );
};

// Рефакторинг
type ContainerTwoProps = {
  size?: Size;
  children: React.ReactNode;
  breakpoints?: {
    md?: Size;
    lg?: Size;
    xl?: Size;
    xxl?: Size;
  }
};
export const ContainerTwo: React.FC<ContainerTwoProps> = ({
  children,
  breakpoints,
  size,
}) => {
  let breakpointsValue = "";

  if (breakpoints) {
    if (breakpoints.md) {
      breakpointsValue += `container--md-${breakpoints.md} `
    }
    if (breakpoints.lg) {
      breakpointsValue += `container--lg-${breakpoints.lg} `
    }
    if (breakpoints.xl) {
      breakpointsValue += `container--xl-${breakpoints.xl} `
    }
    if (breakpoints.xxl) {
      breakpointsValue += `container--xll-${breakpoints.xxl} `
    }
  }

  return (
    <div
      className={`container ${classNames(
        size ? `container--${size}` : "",
        breakpointsValue
      )}`}
    >
      {children}
    </div>
  );
};
