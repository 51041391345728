import React from "react";
import {Badge} from "../UI/Badge";
import {CountdownRenderProps} from "react-countdown/dist/Countdown";
import Countdown from "react-countdown";


const renderer = ({ minutes, seconds, completed }: CountdownRenderProps) => {
  const text = completed
    ? 'expired'
    : `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  return <Badge text={text} />
};
type TimerPros = {
  time: number
}
export const Timer: React.FC<TimerPros> = ({time}) =>
  <Countdown date={Date.now() + time * 1000} renderer={renderer}/>

