import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { Chat } from '../../../../components/Chat'
import { Page } from '../../../../components/Page'
import { Timer } from '../../../../components/Timer'
import {
  BodyRegularM,
  BodySemiboldM,
  Button,
  Mark,
  Message
} from '../../../../components/UI'
import { Avatar } from '../../../../components/UI/Avatar'
import { Badge } from '../../../../components/UI/Badge'
import { Divider } from '../../../../components/UI/Divider'
import { Column } from '../../../../components/UI/Grid/Column'
import { Row } from '../../../../components/UI/Grid/Row'
import { VerticalOffset } from '../../../../components/UI/Grid/VerticalOffset'
import { IconText } from '../../../../components/UI/IconText'
import { TablePaymentStatus } from '../../../../components/UI/TableData'
import { BodyRegularL } from '../../../../components/UI/Text'
import { ParamContext } from '../../../../context/paramReducer'
import { cancel, sendMessage, setPaid } from '../../invoiceAPI'
import { selectStatus } from '../../invoiceSlice'
import { languageType } from '../../types'

const getFieldName = (k: string) => {
  const lang = new Map(
    Object.entries({
      L_PS_DETAILS_FIELD_1: 'Account number',
      L_PS_DETAILS_FIELD_2: 'Card number',
      L_PS_DETAILS_FIELD_3: 'Full name',
      L_PS_DETAILS_FIELD_4: 'DOB',
      L_PS_DETAILS_FIELD_5: 'Phone number',
      L_PS_DETAILS_FIELD_6: 'ЮMoney wallet',
      L_PS_DETAILS_FIELD_7: 'Email',
      L_PS_DETAILS_FIELD_8: 'Bik',
      L_PS_DETAILS_FIELD_9: 'Comment',
      L_PS_DETAILS_FIELD_10: 'Bank name',
      L_PS_DETAILS_FIELD_11: 'Mobile operator',
      L_PS_DETAILS_FIELD_12: 'Contract number',
      L_PS_DETAILS_FIELD_13: 'City',
      L_PS_DETAILS_FIELD_14: 'Full name or company name',
      L_PS_DETAILS_FIELD_15: 'IBAN',
      L_PS_DETAILS_FIELD_16: 'BIC',
      L_PS_DETAILS_FIELD_17: 'Recipient country',
      L_PS_DETAILS_FIELD_18: 'EPG MerchantId',
      L_PS_DETAILS_FIELD_19: 'EPG key',
      L_PS_DETAILS_FIELD_25: 'EPG password',
      L_PS_DETAILS_FIELD_20: 'eMerchantPay логин',
      L_PS_DETAILS_FIELD_21: 'eMerchantPay пароль',
      L_PS_DETAILS_FIELD_22: 'eMerchantPay токен',
      L_PS_DETAILS_FIELD_23: 'Klarna логин',
      L_PS_DETAILS_FIELD_24: 'Klarna пароль',
      L_PS_DETAILS_FIELD_26: 'Bank address',
      L_PS_DETAILS_FIELD_27: "Recipient's country",
      L_PS_DETAILS_FIELD_28: "Recipient's address",
      L_PS_DETAILS_FIELD_29: 'Sofort config key',
      L_PS_DETAILS_FIELD_30: 'Qiwi Client Id',
      L_PS_DETAILS_FIELD_31: 'Qiwi Client Secret',
      L_PS_DETAILS_FIELD_32: 'Billing link',
      L_PS_DETAILS_FIELD_33: 'Qiwi Wallet',
      L_PS_DETAILS_FIELD_34: 'Decta secret key',
      L_PS_DETAILS_FIELD_40: 'PaymentCenter secret key',
      L_PS_DETAILS_FIELD_41: 'VISA\\MasterCard\\WORLD (PCEURO)',
      L_PS_DETAILS_FIELD_42: 'VISA\\MasterCard\\WORLD (PCRUB)',
      L_PS_DETAILS_FIELD_43: 'VISA\\MasterCard\\WORLD (PCDOLLAR)',
      L_PS_DETAILS_FIELD_44: 'S3PAY CAM',
      L_PS_DETAILS_FIELD_45: 'S3PAY CID',
      L_PS_DETAILS_FIELD_46: 'S3PAY Secret key',
      L_PS_DETAILS_FIELD_47: "Cardholder's name",
      L_PS_DETAILS_FIELD_48: 'First name',
      L_PS_DETAILS_FIELD_49: 'Last name',
      L_PS_DETAILS_FIELD_50: 'Secret key',
      L_PS_DETAILS_FIELD_51: 'Passport series (if exists)',
      L_PS_DETAILS_FIELD_52: 'Passport number (or ID)',
      L_PS_DETAILS_FIELD_53: 'Bank',
      L_PS_DETAILS_FIELD_54: 'Wallet address',
      L_PS_DETAILS_FIELD_55: 'TIN',
      L_PS_DETAILS_FIELD_56: 'IEC',
      L_PS_DETAILS_FIELD_57: 'Checking account'
    })
  )
  const key = 'L_PS_DETAILS_FIELD_' + k
  return lang.has(key) ? lang.get(key) : key
}

const UPDATE_TIME = 5000

type StepP2pChatProps = {
  formId: string
  checkStatus: (el: languageType) => void
}

export const StepP2pChat: React.FC<StepP2pChatProps> = ({ formId, checkStatus }) => {
  const { lang } = useContext(ParamContext)
  const status = useAppSelector(selectStatus)

  const [messages, setMessages] = useState<any[]>([])
  useMemo(() => {
    const m = status?.p2pChat.map((c: any) => ({ message: c.text, type: c.type })) || []
    setMessages(m)
  }, [status?.p2pChat.length])

  const onSendMessage = (message: string) => {
    sendMessage(formId, message, lang).then(() => {
      checkStatus(lang)
    })
  }
  const onPaid = () => {
    setPaid(formId, lang).then((data) => {
      console.log('data', data)
      checkStatus(lang)
    })
  }

  const onCancel = () => {
    cancel(formId, lang).then((data) => {
      console.log('data', data)
      checkStatus(lang)
    })
  }
  useEffect(() => {
    const updater = setInterval(() => {
      checkStatus(lang)
    }, UPDATE_TIME)
    return () => {
      clearInterval(updater)
    }
  }, [lang])
  if (!status) {
    return <></>
  }

  const details = Object.entries<[string, string]>(status.p2pOrder.withdrawDetails)
    .map(([key, value]) => `${getFieldName(key)}: ${value}`)
    .join(', ')

  return (
    <Page title="Payment by P2P" width="full">
      <VerticalOffset offset="level3">
        <Message>
          <BodyRegularM>
            You are paying: invoice #{status.invoiceId} from ???
          </BodyRegularM>
          <BodySemiboldM>
            Please send <Mark mark="primary">{status.p2pOrder.amount}</Mark> to{' '}
          </BodySemiboldM>
          <BodyRegularM>{details}</BodyRegularM>
        </Message>

        <Row justify="space-between">
          <Column col={'auto'}>
            <BodyRegularL>Chat with P2P merchant</BodyRegularL>
          </Column>
          <Column col={'auto'}>
            <IconText>
              <Avatar name="LN" size="s" color="blue" />
              <BodyRegularM>Lucas Name</BodyRegularM>
            </IconText>
          </Column>
        </Row>

        <Chat onSubmit={onSendMessage} messages={messages} />

        <Row justify="space-between">
          <Column col={'auto'}>
            {status.p2pOrder.status === 2 && <Badge text="Payment awaiting" />}
            {status.p2pOrder.status === 13 && <Badge text="Confirmation awaiting" />}
          </Column>
          <Column col={'auto'}>
            <Timer time={status.time} />
          </Column>
        </Row>

        <Divider type="solid" />

        <Row rowGap="level2" justify="space-between">
          <Column col={12} sm={{ col: 'auto' }}>
            {status.p2pOrder.status === 2 && (
              <Button onClick={onPaid} width="full-mobile" size="medium">
                <BodyRegularM>I Paid</BodyRegularM>
              </Button>
            )}
            {status.p2pOrder.status === 13 && <TablePaymentStatus status="waiting" message='waiting' />}
          </Column>

          <Column col={12} sm={{ col: 'auto' }}>
            <Button
              onClick={onCancel}
              width="full-mobile"
              size="medium"
              variant="secondary"
            >
              <BodyRegularM>Cancel</BodyRegularM>
            </Button>
          </Column>
        </Row>
      </VerticalOffset>
    </Page>
  )
}
