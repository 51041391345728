import React, { useState } from 'react';
import { PageMain } from './Pages';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Invoice} from "./features/invoice/Invoice";
import {Page} from "./components/Page";
import { languageType } from './features/invoice/types';
import { ParamContext } from './context/paramReducer';

function App() {
  const [lang, setLang] = useState<languageType>("en")

  return (
    <div className="App">
    <ParamContext.Provider value={{
      lang,
      setLang
    }}>
      <PageMain>
        <BrowserRouter>
          <Routes>
            <Route path="/invoices/:formId" element={<Invoice />} />
            <Route path="*" element={<Page title="Error">this link does not exist...</Page>} />
          </Routes>
        </BrowserRouter>
      </PageMain>
      </ParamContext.Provider>
    </div>
  )
}

export default App
