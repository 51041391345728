import React, { useState } from "react";
import "./checkbox.scss";
import { BodyRegularM } from "../Text";

type CheckboxProps = {
  name: string;
  value: string;
  checked?: boolean;
  setCheck?: ()=> void;
  disabled?: boolean;
  label?: string;
  children?: React.ReactNode;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  name = " ",
  value = " ",
  checked = false,
  setCheck,
  disabled = false,
  label,
  children,
}) => {
  const [checkboxChecked, setCheckboxChecked] = useState(checked)

  const changeHandler = () => {
    if(setCheck){
      setCheck()
    }
      setCheckboxChecked(!checkboxChecked)
  }

  return (
    <div className="checkbox">
      <label className="checkbox__label" htmlFor={name}>
        <input
          className="checkbox__input"
          type="checkbox"
          name={name} id={name}
          value={value}
          onChange={changeHandler}
          checked={checkboxChecked}
          disabled={disabled}
        />
        <span className="checkbox__visual"></span>

        {label &&
          <div className="checkbox__content">
            <BodyRegularM>{label}</BodyRegularM>
          </div>
        }
      </label>

      {children}
    </div>
  )
}