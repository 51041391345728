import React, { useState } from "react";
import "./copy-text.scss";
import { BodyRegularM } from "../Text";
import { Button } from "../Button";
import { IconCopiedTwo, IconCopy } from "../Icon";

type CopyTextProps = {
  children: string;
};

export const CopyText: React.FC<CopyTextProps> = ({ children }) => {
  const [check, setCheck] = useState(false);
  const copyHandler = () => {
    navigator.clipboard.writeText(children).then(
      () => {
        setCheck(true);
        setTimeout(() => {
          setCheck(false);
        }, 5000);
      },
    );
  };
  return (
    <div className="copy-text">
      <div className="copy-text__body">
        <BodyRegularM tag="span">{children}</BodyRegularM>

        <div className="copy-text__button">
          <Button onClick={copyHandler} variant="tertiary">
            {check ? <IconCopiedTwo /> : <IconCopy />}
          </Button>
        </div>
      </div>
    </div>
  );
};
type CopyItemProps = {
  children: React.ReactNode;
  value: string;
};

export const CopyItem: React.FC<CopyItemProps> = ({ children, value }) => {
  const [check, setCheck] = useState(false);
  const copyHandler = () => {
    navigator.clipboard.writeText(value).then(
      () => {
        setCheck(true);
        setTimeout(() => {
          setCheck(false);
        }, 5000);
      },
    );
  };
  return (

    <>
      <div className="copy-item">
        <div className="copy-item__children">
          {children}
        </div>
        <Button onClick={copyHandler} variant="tertiary">
          {check ? <IconCopiedTwo size="sm" /> : <IconCopy size="sm" />}
        </Button>
      </div>
    </>

  );
};
