import React from "react";
import "./message.scss";

type MessageProps = {
  children: React.ReactNode;
};

export const Message: React.FC<MessageProps> = ({
  children,
}) => (
  <div className="message">
    {children}
  </div>
);
